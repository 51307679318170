import { ref } from 'vue';
import { Toast, Tab, Tabs } from 'vant';
import 'vant/es/toast/style';
export default {
  setup() {
    const onClickLeft = () => history.back();
    const onClickRight = () => Toast('查看更多');
    const active = ref('mb-equity');
    const activetabs = ref(0);
    return {
      active,
      onClickLeft,
      onClickRight,
      activetabs
    };
  }
};